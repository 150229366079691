(function ($) {

  "use strict";

  function SwiperSlider () {
    
    let initSpaceSlider = function ($swiper) {
      $swiper.each(function () {
        let $currentSwiper = $(this);

        let singleSlider = new Swiper($currentSwiper, {
          speed: 900,
          threshold: 30,
          slidesPerView: 'auto',
          centeredSlides: true,
          spaceBetween: 10,
          breakpoints: {
            // when window width is >= 468px
            468: {
              spaceBetween: 10,
            },
            // when window width is >= 768px
            768: {
              spaceBetween: 10,
            },
            // when window width is >= 1024px
            1024: {
              spaceBetween: 10,
            },
            // when window width is >= 1440px
            1440: {
              spaceBetween: 10,
            }
          },
          navigation: {
            nextEl: $currentSwiper.find('.js-swiper-button-next'),
            prevEl: $currentSwiper.find('.js-swiper-button-prev')
          },
          pagination: {
            el: $swiper.find('.js-swiper-pagination'),
            type: 'bullets',
          },
        });
      });
    };
    
    let init = function (parentElement) {
      // Single slider (one slider on all viewports)
      let $singleSlider = $('.js-single-slider', parentElement);
      if ($singleSlider.length) {
        initSpaceSlider($singleSlider);
      }
    };

    this.init = function (element) {
      let parentElement = element ? element : $('body');
      init(parentElement);
    };
  }

  window.SwiperSlider = new SwiperSlider();

})(jQuery);
