(function ($) {

  "use strict";

  function Common () {
    let bindSmoothScrollToElement = function () {
      // handle links with @href started with '#' only
      $(document).on('click', 'a[href^="#"]:not([data-toggle="tab"])', function(e) {
        // target element id
        var id = $(this).attr('href');

        // target element
        var $id = $(id);
        if ($id.length === 0) {
          return;
        }

        // prevent standard hash navigation (avoid blinking in IE)
        e.preventDefault();

        // top position relative to the document
        var pos = $id.offset().top;

        // animated top scrolling
        $('body, html').animate({scrollTop: pos}, 800);
      });
    };

    let bindHambuger = function () {
      let $hamb = $('.js-hamburger');
      let $mainMenu = $('.js-main-menu');

      $hamb.on('click', function () {
        $hamb.find('> div').toggleClass('active');
        $('body').toggleClass('mobile-nav-active main-menu-active');
        $mainMenu.toggleClass('active');
      });
    };

    let bindSpaceAccordion = function ($spaceItem) {
      let $spaceItemButton = $('.js-space-item-button', $spaceItem);

      $spaceItemButton.on('click', function () {
        let $self = $(this);
        let $currentSwiper = $self.closest($spaceItem).find('.js-space-slider');

        $self.toggleClass('active');
        $self.closest($spaceItem).find('.js-space-item-content').toggle(300);

        if (!$self.hasClass('slider-activated')) {
          setTimeout(function () {
            let spaceSlider = new Swiper($currentSwiper, {
              speed: 900,
              threshold: 30,
              navigation: {
                nextEl: $currentSwiper.find('.js-swiper-button-next'),
                prevEl: $currentSwiper.find('.js-swiper-button-prev')
              },
              slidesPerView: 1,
              centeredSlides: true,
              spaceBetween: 0,
              autoplay: {
                delay: 2000,
              },
              pagination: {
                el: $currentSwiper.find('.js-swiper-pagination'),
                type: 'bullets',
              },
            });

            $self.addClass('slider-activated');
          }, 500);
        }
      })
    };

    let init = function (parentElement) {
      let $header = $('.js-header', parentElement);
      let $scrollHeaderBody = $('body.js-scroll-header-alt');
      let $spaceItem = $('.js-space-item');
      let $accordion = $('.js-accordion');
      let lastScroll = 0;

      if ($header.length) {
        bindSmoothScrollToElement();
        bindHambuger();
      }

      if ($spaceItem.length) {
        bindSpaceAccordion($spaceItem);
      }

      if ($accordion.length) {
        let $accordionButton = $('.js-accordion-button', $accordion);
        let $accordionContent = $('.js-accordion-content', $accordion);

        $accordionButton.on('click', function () {
          $(this).toggleClass('is-open');
          $(this).closest($accordion).find($accordionContent).toggle(300);
        });
      }

      function checkPosition ($scrollHeaderBody) {
        $header.toggleClass('is-scrolled', $(window).scrollTop() > 0);
      }

      checkPosition($scrollHeaderBody, $header);

      $(window).scroll(function () {
        checkPosition($scrollHeaderBody, $header);

        let scroll = $(window).scrollTop();

        if(lastScroll - scroll > 0) {
          $header.addClass('is-scrolled-up');
          $header.removeClass('is-scrolled-down');
        } else {
          $header.removeClass('is-scrolled-up');
          $header.addClass('is-scrolled-down');
        }

        lastScroll = scroll;
      });
    };

    this.init = function (element) {
      let parentElement = element ? element : $('body');
      init(parentElement);
    };
  }

  window.Common = new Common();

})(jQuery);
